.cardShadow {
  -webkit-box-shadow: 4px 4px 7px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 7px 5px rgba(0, 0, 0, 0.5);
}
.modalBackdrop {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
.disabled {
  pointer-events: none;
  color: gray;
  border: 1px solid gray;
}
