@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.text-color--green {
  color: #45624e;
  font-family: 'open sans';
}
.text-color--red {
  color: #b94951;
  font-family: 'open sans';
}
.border--green {
  border-color: #45624e;
}
.border--red {
  border-color: #b94951;
}

.button--regular {
  border-color: #45624e;
  color: #45624e;
}

.button--regular:hover {
  background-color: #45624e;
  color: #fff;
}

.button--danger {
  border-color: #b94951;
  color: #b94951;
}

.button--danger:hover {
  background-color: #b94951;
  color: #fff;
}

.button--warning {
  border-color: #fcbf49;
  color: #fcbf49;
}

.button--warning:hover {
  background-color: #fcbf49;
  color: #fff;
}

.button--footer {
  border-color: #fff;
  color: #fff;
}
.button--footer:hover {
  background-color: white;
  color: #45624e;
}

.background--green {
  background-color: #45624e;
  color: #fff;
}
.background--red {
  background-color: #b94951;
  color: #fff;
}
.modalBackdrop {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
.disabled {
  pointer-events: none;
  color: gray;
  border: 1px solid gray;
}
