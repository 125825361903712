.background {
  background-color: #b94951;
}
.logo {
  max-height: 100px;
  height: 100px;
  position: relative;
}
.logoMobile {
  max-height: 75px;
  height: 75px;
  position: relative;
}
.logoSvg {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
}
.menuItem {
  padding: 10px 20px;
  min-height: 100%;
}
.menuItem:hover {
  background-color: white;
  color: #45624e;
}
