.modalBackdrop {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
.disabled {
  pointer-events: none;
  color: gray;
  border: 2px solid gray;
}
.hideScrollBar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hideScrollBar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
